import React from 'react';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import {auroraDataToGraphData} from '../../repositories/auroraData';
import MapStyle from './Map.module.scss';

interface iPlotState {
  data?: Data[];
  lastUpdateAt?: string;
  width: number;
  height: number;
  interval?: number;
}

export default class Map extends React.Component<{}, iPlotState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      width: 100,
      height: 100,
    };
  }

  componentDidMount() {
    this.loadData();
    this.setDimentions();
    this.setEventListners();
    this.setIntervals();

  }

  componentWillUnmount() {
    const { interval } = this.state;
    window.removeEventListener('resize', this.setDimentions);
    if (interval) window.clearInterval(interval);
  }

  loadData = async () => {
    const { data, lastUpdateAt } = await auroraDataToGraphData();
    this.setState({ data, lastUpdateAt });
  }

  setEventListners = () => {
    window.addEventListener('resize', this.setDimentions);
  }

  setIntervals = () => {
    const interval = window.setInterval(this.loadData, 10000)
    this.setState({ interval })
  }

  setDimentions = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

    this.setState({
      width: vw,
      height: vh - 150,
    });
  }

  layout = (): Layout => {
    const { width, height } = this.state;

    return {
      width,
      height,
      margin: {
        t: 0,
        b: 0,
        l: 0,
        r: 0,
        pad: 0,
      },
      autosize: true,
      hovermode:'closest',
      showlegend: false,
      mapbox: {
        center: {
          lat: 71.28609560081495,
          lon: 22.507328342526908,
        },
        domain: {
          x: [0, 1],
          y: [0, 1]
        },
        style: 'carto-darkmatter', // open-street-map, white-bg, carto-positron, carto-darkmatter, stamen-terrain, stamen-toner, stamen-watercolor
        zoom: 3
      },
      } as Layout
    };

  render() {
    const { data, lastUpdateAt } = this.state;

    if (!data || !lastUpdateAt) return (<div>loading</div>)

    const date = lastUpdateAt.split(/[^0-9]/)
    const time = `${date[3]}:${date[4]}:${date[5]} UTC`

    return (
      <div className={MapStyle.mapContainer}>
        <div className={MapStyle.plotContainer}>
          <Plot
            data={data}
            layout={this.layout()}
            config={{ scrollZoom: false }}
          />
        </div>
        <div className={MapStyle.timelet}>
          Last update:
          { " " }
          { time }
          <br />
          <a href="https://git.hendrikpeter.net/hendrikpeter/aurora-bot-map" target="_blank">More info and source</a>
        </div>
      </div>
    );
  }
}
