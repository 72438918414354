import React from 'react';
import Header from '../Header/Header';
import './reset.style.scss';
import './App.style.scss';
import Map from '../Map/Map';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Map />
    </div>
  );
}

export default App;
