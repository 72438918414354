import { Data } from "plotly.js";

interface iLocation {
  average_deviation: number;
  average_total: number;
  human_readable_name: string;
  kp: number;
  lat: number;
  long: number;
  quiet_mean_tot: number;
}

interface iAuroraData {
  last_update_at: string,
  locations: {
    andenes: iLocation;
    beerenberg: iLocation;
    jackvik_se: iLocation;
    karmoy: iLocation;
    nordkap: iLocation;
    svalbard: iLocation;
    tromso: iLocation;
  }
}

type iLocationName = "andenes" | "beerenberg" | "jackvik_se" | "karmoy" | "nordkap" | "svalbard" | "tromso";

export const getAuroraData = async () => {
  const response = await fetch('https://aurora.hendrikpeter.net/api/aurora_data.json')
  if (response.status !== 200) throw new Error('connection error');

  const responseData: iAuroraData = await response.json()
  return responseData;
};


export const auroraDataToGraphData = async () => {
  const { locations, last_update_at } = await getAuroraData()
  const locationNames = Object.keys(locations) as iLocationName[];

  const data = locationNames.map((name) => {
    const location = locations[name]
    const kpText = location.kp === null ? 'offline' : `Kp${location.kp}`
    const color = location.kp === null ? 'rgba(200, 200, 200, 0.8)' : `rgba(83, 207, 85, ${Math.min((location.kp / 7) + 0.3, 1)})`

    return {
      type:'scattermapbox',
      lat:[location.lat.toString()],
      lon:[location.long.toString()],
      mode:'markers',
      marker: {
        size: (((location.kp || 0) + 1) * 3) + 5,
        color,
      },
      text:[`${location.human_readable_name} ${kpText}`],
      name: '',
    } as Data
  });

  return {data, lastUpdateAt: last_update_at}
};

