import React from 'react';
import HeaderStyle from './header.module.scss';
import backgroundImage from './headerBg.jpg';
import marvinLogo from './marvinLogo.png';

const Header: React.FC = () => (
  <div className={HeaderStyle.header} style={{ backgroundImage: `url(${backgroundImage})` }}>
    <div className={HeaderStyle.headerInner}>
      <img src={marvinLogo} alt="" width="300" className={HeaderStyle.headerLogo} />
    </div>
  </div>
)

export default Header;
